import * as React from 'react'
import Layout from '../layouts/layout'
import { Link } from 'gatsby';
import { section } from '../styles/styles';
import Photos from '../components/photos';

const galerie = () => {
    return (
        <Layout>
            <section className={`${section}`}>
                <div className="wrapper-lg sm:px-6 lg:px-8">
                    <div className="px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
                        <div className="max-w-2xl mx-auto text-center">
                            <h1 className="title sm:text-5xl">
                                Galerie
                                <span className="relative inline-block pl-3"> Photos</span>
                            </h1>
                        </div>
                    </div>
                    <Photos numberImages={100} />
                </div>
            </section>

            {/*   <section className={`${section} bg-gray-900`}>
                <div className="wrapper-lg sm:px-6 lg:px-8">
                    <div className="px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
                        <div className="max-w-2xl mx-auto text-center">
                            <h2 className="title text-primary  sm:text-5xl">
                                Galerie
                                <span className="relative inline-block pl-3">Videos</span>
                            </h2>
                        </div>
                    </div>
                    <Photos numberImages={100} />
                </div>
            </section> */}
        </Layout>
    )
}

export default galerie