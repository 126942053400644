import React from 'react'

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const Photos = ({ numberImages }) => {



    const data = useStaticQuery(graphql`
    query {
        photos: allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
            edges {
                node {
                    id
                    childImageSharp {
                       fixed  {
                            ...GatsbyImageSharpFixed
                        } 
                    }
                  
                }
            }
        }
    }
  `);

    //data.photos.edges.filter(edge => console.log('edge', edge))

    const { photos } = data
    const num = numberImages >= 8 ? numberImages : 8
    const images = photos.edges.slice(0, num)
    return (
        <div className='gap-8 columns-xs py-4 mt-4'>
            {
                images.map(image => {
                    const img = image.node.childImageSharp.fixed.src
                    /*
                                        {
                                            "node": {
                                    "id": "5b34023f-b8b2-5c01-8201-8fb46e58d411",
                                "childImageSharp": {
                                    "fixed": {
                                    "base64": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFDZNiHOLQr/8QAGhAAAQUBAAAAAAAAAAAAAAAAAQACAxESEP/aAAgBAQABBQLZWXOFUoTUnP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGMf//EABgQAAIDAAAAAAAAAAAAAAAAAAAhARAx/9oACAEBAAY/AtEMi//EABoQAAIDAQEAAAAAAAAAAAAAAAARASFhcVH/2gAIAQEAAT8hZDfbL2F7I1GfD0CHRPD/2gAMAwEAAgADAAAAEBjv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxBRmH//xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBUf/aAAgBAgEBPxBHbJaf/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFhQZH/2gAIAQEAAT8QEhiYswzYK9vZyZGYmJjAvstYvhcQvH5P/9k=",
                                "width": 400,
                                "height": 267,
                                "src": "/static/9aaf1c6718939ff9622bbba9d8dab9bd/2244e/IMG_9382.jpg",
                                "srcSet": "/static/9aaf1c6718939ff9622bbba9d8dab9bd/2244e/IMG_9382.jpg 1x,\n/static/9aaf1c6718939ff9622bbba9d8dab9bd/4fe8c/IMG_9382.jpg 1.5x,\n/static/9aaf1c6718939ff9622bbba9d8dab9bd/14b42/IMG_9382.jpg 2x"
                          }
                        }
                      }
                    }
                    */
                   // const img = getImage(image.node.childImageSharp)
                   // console.log('image', image);
                    return (
                        <div key={image.node.id} className="relative max-h-[350px] flex flex-col justify-self-center w-full  transition-all duration-200 transform  group  bg-slate-50 rounded-xl hover:shadow-lg aspect-auto mb-8 overflow-hidden">
                            {/* <GatsbyImage image={img}
                                className='w-full aspect-auto transition-all duration-700 hover:scale-125'
                                alt='knr events'
                            /> */}
                            <img src={img} className='w-full aspect-auto transition-all duration-700 hover:scale-125' alt='knr events' /> 
                        </div>
                    )
                })
            }  
        </div>
    )
}

export default Photos